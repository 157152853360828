@charset "UTF-8";
.input-field-scan input {
  background-color: #f4f4f4;
  max-width: 80%;
}

.fake-btn-inner-block {
  display: inline-block;
  padding: 10px;
}

.fa-red {
  color: red;
}

.btn-default-in-at {
  background-color: #ffffff;
  padding: 5px;
  border: 1px solid #777676;
}

.btn-send-in-at {
  background-color: #337ab7;
  padding: 5px 9px;
  color: white;
}

.btn-resend-in-at {
  background-color: #5747cf;
  color: white;
}

#qrCodeScannerWrapper {
  padding-left: 20%;
  padding-right: 20%;
}

.holi-table tr.Aprovado {
  background-color: rgba(225, 244, 223, 0.65);
}

.holi-table tr.aceite {
  background-color: rgba(255, 155, 155, 0.43);
}

.holi-table tr.Cancelado {
  background-color: rgba(0, 0, 0, 0.13);
}

.qrcode-as-back {
  background-position: right center;
  background-repeat: no-repeat;
  background-size: auto 100%;
}

.warning-wrap {
  color: red;
}

.each-day-scheduele-wrap {
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
  margin-top: 0px;
  margin-bottom: 20px;
  margin-left: 0px;
  margin-right: 0px;
}

.btn-fake-chosen-shelf {
  background-color: #f4f4f4;
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid #e2e2e2;
}

.fake-btn-inner-block-right {
  border-left: 1px solid #bfbfbf;
}

.label-inside-eachstoragebyscan-inner {
  background-color: #fff;
  padding: 8px;
}

.label-inside-eachstoragebyscan-inner-inner {
  border: 1px solid #f5f5f5;
  padding-top: 8px;
  padding-bottom: 8px;
}

.fa-fat {
  font-size: 30pt;
}

.fa-green {
  color: #3fa65b;
}

.fa-grey {
  color: #38414a;
}

.fa-red {
  color: red;
}

/*COLOCAR DEFINIÇÔES dos pdfs  AKI*/
@media only screen and (max-width: 480px) {
  #qrCodeScannerWrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
  .no-padding-good-xs {
    padding-left: 0px;
    padding-right: 0px;
  }
  .orders-list-background .form-control-xs-fail-to50 {
    width: 50%;
    min-height: 35px;
  }
  .orders-list-background .form-control-xs-fail-label {
    width: 50%;
    min-height: 35px;
    line-height: 30px;
    margin-bottom: 0px;
    float: left;
  }
  .float-none-xs {
    float: none;
  }
  .btn {
    padding: 10px 15px;
  }
  .anitem-wrapper .anitem-each-line {
    padding-left: 0px;
    padding-right: 0px;
  }
  #crudTable_filter > label {
    font-size: 1px;
    color: aliceblue;
  }
  .backpack-profile-form .image {
    text-align: center;
    padding-bottom: 15px;
  }
  .barCodeScannerWrapper {
    padding-left: 0px;
    padding-right: 0px;
    max-width: 100%;
    margin-left: 0px !important;
  }
  .mainCustomImage {
    text-align: center;
    padding-bottom: 15px;
    background-color: rgba(236, 240, 245, 0);
  }
  .hidden-print > .ladda-button > .ladda-label {
    font-size: 0px;
    color: rgba(240, 248, 255, 0);
    text-align: center;
  }
  .hidden-print > .ladda-button > .ladda-label .fa {
    font-size: 14px;
    color: rgb(255, 255, 255);
  }
  .tab-container.col-xs-12 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .nav-tabs-custom > .nav-tabs > li {
    font-size: 11px;
    margin-right: 0px;
  }
  .nav-tabs-custom > .nav-tabs > li a {
    padding-left: 10px;
    padding-right: 10px;
  }
  .tab-content.panel.col-md-12 {
    padding-left: 0px;
    padding-right: 0px;
  }
  #crudTable td {
    max-width: 240px !important;
  }
  table.dataTable.nowrap th,
  table.dataTable.nowrap td {
    white-space: normal;
  }
  #employeeUpload .box-body .row:nth-child(1) .col-md-12 button {
    float: right;
  }
  #employeeUpload .box-body .row:nth-child(1) .col-md-12 a:last-child {
    margin: 0 auto;
    margin-top: 10px;
    display: block;
  }
  #employeeUpload .box-body .row:nth-child(3) .col-md-12 section ol {
    padding: 0 0 0 15px;
  }
  .table-striped tbody tr td:first-child {
    padding: 10px 0;
    padding-left: 15px;
  }
  .table-striped tbody tr td:last-child {
    text-align: center;
    padding: 0;
    vertical-align: middle;
  }
}
.barCodeScannerWrapper {
  overflow: hidden;
  display: grid;
  justify-items: center;
}

.barCodeScannerWrapper .barCodeScannerWrapper-inner {
  left: -50px;
  top: -50px;
  min-width: 500px;
}

.alert-danger a {
  color: #fff;
}

.form-check-inline-new {
  display: inline-block;
  padding-right: 15px;
  font-size: 16px;
}

.form-check-inline-new input[type=radio] {
  position: relative;
  margin-left: 0px;
}
.form-check-inline-new input[type=radio]:after {
  background-color: #008d4c;
}

.form-check-inline-new label {
  padding-left: 0px;
}

h2 .small-info {
  font-size: 10pt;
  padding-left: 15px;
}

.inline-block {
  display: inline-block;
}

.btn-group-align-with-title {
  margin-top: 20px;
}

.form-custom-separator .h3-form-separator {
  padding-top: 25px;
  padding-bottom: 15px;
  padding-right: 25px;
  padding-left: 25px;
}

.form-check-inline-new {
  display: inline-block;
  padding-right: 15px;
}

.form-check-inline-new input[type=radio] {
  position: relative;
  margin-left: 0px;
}

.form-check-inline-new label {
  padding-left: 0px;
}

h2 .small-info {
  font-size: 10pt;
  padding-left: 15px;
}

.inline-block {
  display: inline-block;
}

.btn-group-align-with-title {
  margin-top: 20px;
}

.form-custom-separator .h3-form-separator {
  padding-top: 25px;
  padding-bottom: 15px;
  padding-right: 25px;
  padding-left: 25px;
}

.form-check-inline-new {
  display: inline-block;
  padding-right: 15px;
}

.form-check-inline-new input[type=radio] {
  position: relative;
  margin-left: 0px;
}

.form-check-inline-new label {
  padding-left: 0px;
}

h2 .small-info {
  font-size: 10pt;
  padding-left: 15px;
}

.inline-block {
  display: inline-block;
}

.btn-group-align-with-title {
  margin-top: 20px;
}

.form-custom-separator .h3-form-separator {
  padding-top: 25px;
  padding-bottom: 15px;
  padding-right: 25px;
  padding-left: 25px;
}

.anitem-each-line.anitem-each-line-fake-title {
  font-size: 24px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(56, 65, 74, 0.2);
}

.sidebar-menu,
.main-sidebar .user-panel,
.sidebar-menu > li.header {
  white-space: normal;
  overflow: hidden;
}

.report-img img {
  max-width: 100%;
  height: 300px;
}

#fotorama_outer_div_id_here {
  background-color: #fff;
}

.sortable-photos .img-has-back {
  max-width: 100%;
  height: 200px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  padding-bottom: 15px;
}

.sortable-photos li {
  display: inline-block;
  list-style: none;
  cursor: pointer;
  background-color: #fff;
  border-right: 10px solid transparent;
  border-bottom: 10px solid transparent;
  padding: 10px;
  background-clip: padding-box;
  background-color: #ecf0f5;
  border-radius: 4px;
}

.photoLabelOnOrderPhotos {
  margin-top: 5px;
  width: 100%;
  border: 0;
  border-bottom: 1px solid #333;
  background-color: #ffffff;
  padding: 5px;
  font-size: 15px;
}
.photoLabelOnOrderPhotos:focus {
  outline: 0;
}

.deleteImageOnOrderPhotos {
  margin-top: 10px;
  margin-bottom: 0;
}
.deleteImageOnOrderPhotos input {
  vertical-align: text-top;
  margin-right: 3px;
}

.info-block {
  background-color: #ccc;
  padding-bottom: 15px;
  padding-top: 15px;
  margin-bottom: 25px;
}

.info-block .italic {
  font-style: italic;
  font-size: 9pt;
}

#accordion-container {
  font-size: 13px;
  background: #ffffff;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

.accordion-header {
  font-size: 16px;
  background: #f5f5f5;
  margin: 5px 0 0 0;
  padding: 20px;
  cursor: pointer;
  color: #008d4c;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}

.accordion-header-not {
  font-size: 16px;
  background: #f5f5f5;
  margin: 5px 0 0 0;
  padding: 20px;
  cursor: pointer;
  color: #008d4c;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  color: #ccc;
  border-left: 15px solid;
}

.active-header {
  -moz-border-radius: 5px 5px 0 0;
  -webkit-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
  background-repeat: no-repeat;
  background-position: right 50%;
}

.active-header:hover {
  background-repeat: no-repeat;
  background-position: right 50%;
}

.inactive-header {
  background-repeat: no-repeat;
  background-position: right 50%;
}

.inactive-header:hover {
  background-repeat: no-repeat;
  background-position: right 50%;
}

.accordion-content {
  display: none;
  /*     padding: 20px; */
  background: #ffffff;
}

.accordion-content .accordion-header {
  border-left: 15px solid;
}

.accordion-header.noreports {
  color: #ccc;
}

.accordion-header.ko-red {
  border-color: red;
}

.accordion-content #accordion-container {
  padding-left: 20px;
}

.accordion-content .accordion-header {
  background: #ecf0f3;
}

.eatch-content-as-report p,
.eatch-content-as-report {
  font-size: 12pt;
}

.eatch-content-as-report {
  margin-top: 3px;
  margin-bottom: 3px;
  padding: 15px;
  background: #f5f5f5;
}

.no-padding-good {
  padding: 0px;
}

.inline-good {
  display: inline-block;
}

.no-padding-right {
  padding-right: 0px;
}

.form-control.daily-date {
  display: inline-block;
  width: 200px;
}

.report-daily-show .profile-user-img {
  width: 60px;
  padding: 3px;
  border: 3px solid #d2d6de;
  display: inline-block;
  margin-right: 10px;
}

.h2title {
  display: inline-block;
  width: auto;
}

.h2title-img-floater {
  display: inline-block;
  float: right;
}

#newReportModalWrapper.open.newReportModalWrapper-criarcheck {
  width: 100%;
}

.newReportModalWrapper-criarcheck #remove-me {
  max-height: 600px;
  overflow: hidden;
  transition: 0.8s;
  -webkit-transition: 0.8s;
}

.newReportModalWrapper-criarcheck #remove-me.close {
  max-height: 0px;
  padding-bottom: 0px !important;
}

.newReportModalWrapper-criarcheck #target-pop {
  max-height: 0px;
  overflow: hidden;
  transition: 0.8s;
  -webkit-transition: 0.8s;
}

.newReportModalWrapper-criarcheck #target-pop.open {
  max-height: 600px;
}

.report-check-liest {
  background-color: #f9f9f9;
  padding: 15px 15px;
  margin-bottom: 10px;
}

.report-check-liest i {
  color: #00a65a;
}

.titles-show .soci-icons-wrapper.float-right-desktop.small-mobile-buttons {
  margin-top: 11px;
}

.date-as-info {
  font-size: 9pt;
}

.w-100-mobile.a-with-bt .btn {
  position: relative;
  display: block;
  padding: 11px 15px;
  cursor: pointer;
  font-size: 16px;
  border: 1px solid #ddd;
  margin-right: 2px;
}

.eachTypeDiv:disabled a {
  cursor: not-allowed;
}

.dropdown-menu-clients li {
  padding-left: 10px;
  padding-right: 10px;
}

.dropdown-menu-clients input {
  margin: 0px;
  margin-top: -4px;
}

.icon-checkboxes input[type=checkbox] {
  overflow: hidden;
  height: 1px;
  width: 1px;
  opacity: 0;
}

.icon-checkboxes input[type=checkbox] + label:before {
  content: "\f096";
}

.icon-checkboxes input[type=checkbox] + label:before,
.icon-checkboxes input[type=radio] + label:before {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-right: 8px;
  width: 23px;
}

.icon-checkboxes input[type=checkbox]:checked + label:before {
  content: "\f046";
}

.orders-list-background {
  background-color: #f9f9f9;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 5px;
}

.orders-list-background .anitem-wrapper {
  padding-top: 15px;
  padding-bottom: 20px;
  margin-bottom: 0px;
  border-bottom: 1px solid #ccc;
}

.form-control.form-control-but-auto {
  width: 90px;
}

.anitem-each-line {
  padding-top: 7px;
}

.input-code-shelf .multiselect__option {
  padding: 0px;
}

.shelf-color-empty {
  min-height: 40px;
  padding: 12px;
  width: 100%;
  display: block;
  line-height: 16px;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  background-color: rgba(174, 245, 174, 0.43);
  border-bottom: 5px solid #f9f9f9;
}

.shelf-color-empty .fas {
  color: green;
}

.shelf-color-item {
  min-height: 40px;
  padding: 12px;
  width: 100%;
  display: block;
  line-height: 16px;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  background-color: rgba(247, 194, 172, 0.34);
  border-bottom: 5px solid #f9f9f9;
}

.anitem-each-line-paddingtop {
  padding-top: 36px;
}

.anitem-each-line .btn-default {
  margin-top: 3px;
}

td.details-control {
  background: url("/images/details_open.png") no-repeat center center;
  cursor: pointer;
}

tr td.details-control.shown {
  background: url("/images/details_close.png") no-repeat center center;
}

.not-item-check {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 35pt;
}

.not-item-check.ok {
  color: green;
}

.not-item-check.fail {
  color: red;
}

.disabled-but-good {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  opacity: 0.25;
  pointer-events: none;
}